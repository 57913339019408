import "reflect-metadata"
import { EditorService } from "./editor-service";
import { Container,interfaces } from "inversify";
import { GenerateService } from "./generate-service";

export const config = {
    baseURL:  process.env.REACT_APP_BACKEND_URL,
    timeout: 60000,
    headers: {
        'content-type': 'text/plain',
        Accept: 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
};

const container = new Container();

container.bind<GenerateService>(GenerateService).toSelf();
container.bind<EditorService>(EditorService).toSelf();

export default function inject<T>(identifier: interfaces.ServiceIdentifier<T>) {
    return container.get<T>(identifier);
};

