import React from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import Description from '@mui/icons-material/Description';
import Folder from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';
import pathListToTree, { TreeNode } from 'path-list-to-tree';
import { FilesContext } from '../../providers/files-provider';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));




function StyledTreeItem(props: StyledTreeItemProps) {

    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}

            {...other}
        />
    );
}

export interface CodePreviewProps {
    darkTheme: boolean
    onSelect: (value: string) => void
}

function createStyleTreeItem(node: TreeNode, path: string) {
    console.log(node, path)
    return <StyledTreeItem nodeId={path + node.name} labelText={node.name} key={path + node.name}
        labelIcon={node.children.length > 0 ? Folder : Description} >
        {node.children.map((element: TreeNode) => {
            return createStyleTreeItem(element, path + node.name + "/")
        })}
    </StyledTreeItem>
}

export const CodeTree = (props: CodePreviewProps) => {
    const { files } = React.useContext(FilesContext)
    const treeItems = []
    const tree = pathListToTree(Object.keys(files).map((path: string) => path.substring(1)))
    tree.forEach((node: TreeNode) => {
        treeItems.push(createStyleTreeItem(node, "/"))
    })

    return (
        <TreeView
            defaultExpanded={['3']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            onNodeSelect={(event: object, value: string) => { props.onSelect(value) }}

        >
            {treeItems}
        </TreeView>
    );
}