import React, { useState, createContext, ReactNode, useEffect, useContext } from "react";
import { GenerateService } from "../services/generate-service";
import inject from "../services/module";
import { FilesContext } from "./files-provider";
import { SettingsContext } from "./settings-provider";

export interface Model {
    content: string
    valid: boolean
}

interface ModelProviderProps {
    children: ReactNode
}

export type ModelContent = {
    model: Model
    setModel: (model: Model) => void
}

export const ModelContext = createContext<ModelContent>({
    model: { content: "", valid: false },
    setModel: (model: Model) => { }
})

export const ModelProvider = (props: ModelProviderProps) => {

    const generatorService = inject<GenerateService>(GenerateService);

    const [model, setModel] = useState({ content: "", valid: false } as Model)
    const { files, setFiles } = useContext(FilesContext)
    const { settings } = useContext(SettingsContext)
    useEffect(() => {
        if (model.valid) {
            generatorService.generate(model.content, settings.generator, (files: Record<string,string>) => {
                console.log("Generated", files)
                setFiles(files)
            })
        }
    }, [model])

    return (
        <ModelContext.Provider value={{ model, setModel }}>{props.children}</ModelContext.Provider>
    )
}