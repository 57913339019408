import { EditorService } from "../../services/editor-service";
import { useContext, useEffect, useRef } from "react"
import { Example } from "../preview/template"
import { AceFactory } from "./ace-factory"
import inject from "../../services/module"
import * as ace from "ace-builds";
import { useTheme } from "@mui/material";
import { ModelContext } from "../../providers/model-provider";
const langTools = ace.require('ace/ext/language_tools');

export interface EditorProps { }

export const Editor = (props: EditorProps) => {
    
    var timeout: NodeJS.Timeout
    const editorService = inject<EditorService>(EditorService);
    var editorRef = useRef(null as ace.Ace.Editor);

    const theme = useTheme();
    const { model, setModel } = useContext(ModelContext)

    useEffect(() => {
        var editor = null;
        editorRef.current = editor = AceFactory.createEmbeddedEditor("editor", {
            darkMode: true,
            showGutter: true
        })

        const flowCompleter = {
            getCompletions: (editor: ace.Ace.Editor, session: ace.Ace.EditSession, pos: any, prefix: any, callback: any) => {
                editorService.proposal(editor, callback)
            },
        }

        langTools.setCompleters([flowCompleter])
        editor.on('change', () => {
            if (timeout !== null) {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
                editorService.validate(editor.getValue(),
                    (response: string) => {
                        editorService.validateCallback(response, editor)
                        setModel({ content: editor.getSession().getValue(), valid: response.length == 0 })
                    }
                )
            }, 500)
        });

        loadInitialModel(editor);
    }, []);

    function loadInitialModel(editor: ace.Ace.Editor) {
        const modelURL = new URLSearchParams(window.location.search).get("model")
        if (modelURL == null) {
            editor.getSession().setValue(Example)
            return
        }
        fetch(modelURL, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
            .then((response) => response.text())
            .then((text) => editor.getSession().setValue(text)).catch(() => editor.getSession().setValue(Example));
    }

    useEffect(() => {
        editorRef.current.setTheme(theme.palette.mode === 'dark' ? 'ace/theme/tomorrow_night' : 'ace/theme/tomorrow');
    }, [theme])
    return (
        <div id="editor" className="editor" />
    )


}