import * as ace from 'ace-builds';
import axios from 'axios';
import { injectable } from 'inversify';
import { config } from './module'

interface Diagnostic {
    severity: "error" | "warning" | "information"
    startRow: number
    endRow: number
    startColumn: number
    endColumn: number
    message: string
}

interface Proposal {
    score?: number
    label: string
}

@injectable()
export class EditorService {

    private validateUrl = 'api/validate';
    private proposalUrl = 'api/proposals';

    private timeout: NodeJS.Timeout | null = null;

    validate(model: string, callback: (response: any) => void): void {

        if (this.timeout !== null) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
            axios.post(this.validateUrl, model, config).then((res: { data: any; }) => {
                callback(res.data)
            }).catch(() => {});
        }, 500)
    }
    markerIds: Array<number> = []
    validateCallback(response: any, editor: ace.Ace.Editor) {
        var annotations: any[] = []

        this.markerIds.forEach((id) => editor.getSession().removeMarker(id))

        response.forEach((issue: Diagnostic) => {
            console.log("Diagnostics ", response)
            //Annotations
            var severity = issue.severity
            annotations.push({
                row: issue.startRow,
                column: issue.startColumn,
                text: issue.message,
                type: issue.severity
            })
            //Markers
            this.markerIds.push(editor.session.addMarker(
                new ace.Range(
                    issue.startRow,
                    issue.startColumn,
                    issue.endRow,
                    issue.endColumn),
                severity == "error" ? 'ace_error_marker' : 'ace_warning_marker',
                "text",
                true
            ));
        }
        );
        editor.getSession().setAnnotations(annotations)
    }

    proposal(editor: ace.Ace.Editor, callback: (response: string) => void) {
        if (this.timeout !== null) {
            clearTimeout(this.timeout)
        }
        const { row, column } = editor.getCursorPosition()

        axios.post(`${this.proposalUrl}?row=${row}&column=${column}`, editor.getValue(), config).then((res: { data: any; }) => {
            this.proposalCallback(res.data, callback)
        });

    }

    proposalCallback(response: any, callback: any) {
        response.forEach((item: any) => {
            callback(null, [
                {
                    name: item.label,
                    value: item.label,
                    score: 100,
                    meta: '',
                    docHTML: item.documentation
                }])
        });
    }
}