import React, { useState, createContext, ReactNode, useEffect } from "react";

export interface Settings {
    generator: 'React' | 'Angular'
}

interface SettingsProviderProps {
    children: ReactNode
}

export type SettingsContent = {
    settings: Settings
    setSettings:(settings: Settings) => void
  }

export const SettingsContext = createContext<SettingsContent>({
    settings : {generator : "React"},
    setSettings: (settings : Settings) => {}
})

export const SettingsProvider = (props: SettingsProviderProps) => {
    const [settings, setSettings] = useState({generator : "React"} as Settings)
    useEffect(() => {
        console.log("Updated generator to " + settings.generator)
    }, [settings])

    return (
        <SettingsContext.Provider value={{settings, setSettings}}>{props.children}</SettingsContext.Provider>
    )
}