
import React, { createContext, ReactNode } from "react";

interface FilesProviderProps {
    children: ReactNode
}

export type FilesType = {
    files: Record<string, string>
    setFiles: (files: Record<string, string>) => void
}

export const FilesContext = createContext<FilesType>({
    files: { "": "" },
    setFiles: (files: Record<string, string>) => { }
})

export const FilesProvider = (props: FilesProviderProps) => {
    const [files, setFiles] = React.useState(null as Record<string, string>);
    return (
        <FilesContext.Provider value={{ files, setFiles }}>{props.children}</FilesContext.Provider>
    )
}