export const Example = 
`
/* Basic example */
app PersonManagement { 

  //define your entities here
  model { 

    enum Gender {MALE, FEMALE}

    entity Person {
      prop gender: Gender
      prop firstname : string required 
      prop lastname : string required
      prop birthday: date
      prop mail : string email required
      prop age : number min(16) 
      prop favColor : color
      prop photo : image
    }

  } 
  //define your views here
  view {  
    @Root
    form PersonDetails {
      entity Person
    }	
  }
}
`
