import * as React from 'react';
import { AceFactory } from '../editor/ace-factory';
import 'ace-builds/src-noconflict/mode-tsx'
import 'ace-builds/src-noconflict/mode-typescript'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-javascript'
import { CodeTree } from './code-tree';
import * as ace from "ace-builds";
import { Box, useTheme } from '@mui/material';
import { FilesContext } from '../../providers/files-provider';
import { Allotment } from 'allotment';


export interface CodePreviewProps {
    darkTheme: boolean
}

export const CodePreview = (props: CodePreviewProps) => {

    const { files } = React.useContext(FilesContext)

    const modes = {
        tsx: "tsx",
        html: "html",
        ts: "typescript",
        json: "json",
        js: "javascript"
    }
    const theme = useTheme();


    var editorRef = React.useRef(null as ace.Ace.Editor);


    const [file, setFile] = React.useState("");

    React.useEffect(() => {
        editorRef.current = AceFactory.createEmbeddedEditor("code", {
            darkMode: true,
            showGutter: true,
        })
        editorRef.current.getSession().setUseWorker(false);
        editorRef.current.setShowPrintMargin(false);
        editorRef.current.setReadOnly(true);

    }, [])
    React.useEffect(() => {
        editorRef.current.setValue(files[file] || "")
        const mode = `ace/mode/${modes[file.split('.').pop()]}`
        if(mode !== "ace/mode/undefined")
            editorRef.current.getSession().setMode(mode)
        editorRef.current.clearSelection();
    }, [file])

    React.useEffect(() => {
        editorRef.current.setTheme(theme.palette.mode === 'dark' ? 'ace/theme/tomorrow_night' : 'ace/theme/tomorrow');
    }, [theme])

    return (

        <React.Fragment>
            <div style={{height : "90vh"}}>
                <Allotment defaultSizes={[100, 200]} className='preview'>
                   
                        <CodeTree  {...props} onSelect={(value: string) => { setFile(value || "") }} />
                   
                        <Box component="div" id="code" className="code" >
                            Hello
                        </Box>
                  
                </Allotment>
         </div>
        </React.Fragment>
    )
}