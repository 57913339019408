import { Box, Button, CircularProgress, Dialog, DialogTitle, List, ListItem } from "@mui/material";
import { GitHub } from "@mui/icons-material";
import Google from '@mui/icons-material/Google';
import { signInWithGithub, signInWithGoogle } from "../../firebase";

export interface DownloadDialogProps {
    open: boolean;
    onClose: () => void;
}

export const DownloadDialog = (props: DownloadDialogProps) => {
    const { onClose, open } = props;

    return (
        <Dialog onClose={props.onClose} open={open}>
            <DialogTitle>Login</DialogTitle>
            <Box>
            <CircularProgress />
            </Box>
        </Dialog>
    );

}
