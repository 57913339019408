import firebase from 'firebase/app';
import 'firebase/auth';   
import 'firebase/analytics';

const app = firebase.initializeApp(
  {
    apiKey: "AIzaSyCnTfjtuPT_E9JQ_no755Q29cWKWvS8nA8",
    authDomain: "formify-cloud.firebaseapp.com",
    projectId: "formify-cloud",
    storageBucket: "formify-cloud.appspot.com",
    messagingSenderId: "854776996444",
    appId: "1:854776996444:web:3ae6b2fd12b561f8343c99",
    measurementId: "G-X5VD0J4KVK"
  }
)

export const analytics = firebase.analytics();
export const auth = app.auth()
const googleProvider = new firebase.auth.GoogleAuthProvider()
const githubProvider = new firebase.auth.GithubAuthProvider()

export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider).catch((error) => {
    console.log(error.message)
  })
}

export const signInWithGithub = () => {
  auth.signInWithPopup(githubProvider).catch((error) => {
    console.log(error.message)
  })
}
export default app