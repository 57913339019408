import { useSandpack } from "@codesandbox/sandpack-react";
import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useEffect } from "react";

export const PreviewProgress = () => {
    const { listen } = useSandpack();
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        const stopListening = listen((message) => {
            if (message.type === 'start')
                setLoading(true)
            else if (message.type === 'done') {
                setLoading(false)
            }
        });
        return stopListening;
    }, [])

    return (
        loading ?
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="200px"
            >
                <CircularProgress />
            </Box>
            : <React.Fragment />
    )
}