import React, { useContext, useState } from "react"
import { Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, DisabledByDefault } from '@mui/icons-material';
import { Settings, SettingsContext } from "../../providers/settings-provider";

export interface SettingsProps {
    darkTheme: boolean
}

export const SettingsTab = (props: SettingsProps) => {

    const {settings, setSettings} = useContext(SettingsContext)

    return (
        <React.Fragment>
        <Grid container justifyContent="center"
  alignItems="center" spacing={8}>
            <Grid item xs={4}>
                <Card variant={settings.generator == "React" ? "outlined" : "elevation"}>
                    <CardActionArea onClick={() => setSettings({
                        generator: "React"
                    } as Settings)}>
                        <CardHeader
                            action={
                                <IconButton disabled size="large">
                                    {settings.generator == "React" ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank />}
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <img className="center-img" src={process.env.PUBLIC_URL + "/images/settings/react.png"} />

                            <Typography gutterBottom variant="h5" component="div">
                                React
                            </Typography>
                            <Typography variant="body2">
                                Code generator for reactjs.org
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={4}>
            <Card variant={settings.generator == "Angular" ? "outlined" : "elevation"}>
                    <CardActionArea onClick={() => setSettings({
                        generator: "Angular"
                    } as Settings)}>
                        <CardHeader
                            action={
                                <IconButton disabled color="secondary" size="large">
                                    {settings.generator == "Angular" ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank />}
                                </IconButton>
                            }
                        />

                        <CardContent>
                            <img className="center-img" src={process.env.PUBLIC_URL + "/images/settings/angular.png"} />
                            <Typography gutterBottom variant="h5" component="div">
                                Angular
                            </Typography>
                            <Typography variant="body2">
                                Code generator for angular.io
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>

    </React.Fragment>
    );
}