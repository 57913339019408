import axios from 'axios'
import { injectable } from 'inversify'
import {config} from './module'

@injectable()
export class GenerateService {

    apiUrl = 'api/generate';

    private timeout: NodeJS.Timeout | null = null;

    generate(modelJson: string, generator: string, callback: (response: any) => void): void {

        const payload: string = modelJson

        if (this.timeout !== null) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
            axios.post(this.apiUrl + "?generatorId=" + generator, payload, config).then((res: { data: any; }) => {
                callback(res.data)
            }).catch(() => {});
        }, 200)
    }
}