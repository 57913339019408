import React, { useState, useEffect, createContext, ReactNode } from "react";
import { auth } from "../firebase"
export const UserContext = createContext(null as User)

export interface User {
    displayName: string
    email: string
    photoURL: string
    uid : string
}

interface UserProviderProps {
    children: ReactNode
}

export const UserProvider = (props: UserProviderProps) => {
    const [user, setUser] = useState(null)

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user === null) {
                setUser(null)
            } else {
                const { displayName, email, photoURL, uid } = user;
                setUser({
                    displayName,
                    email,
                    photoURL,
                    uid
                } as User)
            }
        })
    }, [])
    return (
        <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
    )
}