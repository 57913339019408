import "reflect-metadata"
import inject from './services/module'
import React, { useContext, useState } from 'react';
import './App.css';
import { Editor } from './components/editor/editor';

import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/';
import { useEffect } from "react";
import { ApplicationBar } from "./components/app-bar/app-bar";
import { UserProvider } from "./providers/user-provider";
import { SettingsProvider } from "./providers/settings-provider";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { PreviewTab } from "./components/preview/preview-tab";
import { ModelProvider } from "./providers/model-provider";
import { FilesContext, FilesProvider } from "./providers/files-provider";
import { GenerateService } from "./services/generate-service";
import { EditorService } from "./services/editor-service";



function App() {

  const { files } = useContext(FilesContext)

  const [loading, setLoading] = React.useState(true)

  inject<GenerateService>(GenerateService);
  inject<EditorService>(EditorService);


  const [darkState, setDarkState] = useState(
    localStorage.getItem('theme') === 'light' ? false : true
  );

  useEffect(() => {
    localStorage.setItem('theme', darkState ? 'dark' : 'light');
  }, [darkState]);

  const darkTheme = createTheme(adaptV4Theme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1D1F21'
      }
    }
  }));
  const lightTheme = createTheme(adaptV4Theme({
    palette: {
      mode: "light",
    }
  }));

  useEffect(() => {
    setLoading(false);

  }, [files])

  return (
    <UserProvider>
      <SettingsProvider>
        <FilesProvider>
          <ModelProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={darkState ? darkTheme : lightTheme}>
                <CssBaseline />
                <ApplicationBar loading={loading} darkMode={darkState} toggleDarkMode={() => setDarkState(!darkState)} />
                <div style={{ height: "calc(100% - 70px)" }}>
                  <Allotment snap={true} defaultSizes={[100, 200]}>
                    <Editor />
                    <PreviewTab darkTheme={darkState} />
                  </Allotment>
                </div>
              </ThemeProvider>
            </StyledEngineProvider>
          </ModelProvider>
        </FilesProvider>
      </SettingsProvider>
    </UserProvider>
  );
}

export default App;
