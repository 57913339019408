import * as React from 'react';
import { AppBar, Tab, Tabs, Box } from '@mui/material/';
import { Preview } from './preview';
import { CodePreview } from './code-preview';
import { Code, Visibility, Settings as SettingsIcon } from '@mui/icons-material';
import { SettingsTab } from '../settings/settings-tab'

export interface PreviewTabProps {
    darkTheme: boolean
}

export const PreviewTab = (props: PreviewTabProps) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    return (

        <React.Fragment>
            <div>
                <AppBar
                    position='static'
                    color='default'

                >
                    <Tabs value={value} onChange={handleChange}
                        indicatorColor='primary'
                        textColor='primary'
                        centered

                    >
                        <Tab label={"Preview"} icon={<Visibility />} />
                        <Tab label={"Code"} icon={<Code />} />
                        <Tab label={"Settings"} icon={<SettingsIcon />} />
                    </Tabs>
                </AppBar>
            </div>
            <TabPanel value={value} index={0}>
                <Preview {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CodePreview {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SettingsTab {...props} />
            </TabPanel>

        </React.Fragment>
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {

    const { children, value, index, ...other } = props;

    return (
        <div
            className="tabPanel"
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}

            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

