import { SandpackPreview, SandpackProvider } from "@codesandbox/sandpack-react"
import React, { useEffect } from "react";
import { FilesContext } from "../../providers/files-provider";
import { PreviewProgress } from "./preview-progress";

export interface PreviewProps {
    darkTheme: boolean
}

export const Preview = (props: PreviewProps) => {
    const { files } = React.useContext(FilesContext)
    const [customSetup, setCustomSetup] = React.useState({
        files: files
    })
    useEffect(() => {
        console.log(files)
        if (files && Object.keys(files).length > 0) {
            setCustomSetup({
                files: { ...files} ,
            })
        }
    }, [files, props.darkTheme]);

    return (
        <div className="preview">
            {customSetup.files && Object.keys(customSetup.files).length > 1  && <SandpackProvider
                customSetup={customSetup}
                recompileMode="immediate"
            >
                <PreviewProgress></PreviewProgress>
                <SandpackPreview

                    showOpenInCodeSandbox={false}
                    showRefreshButton={false}
                    showSandpackErrorOverlay={false}
                />
            </SandpackProvider>
            }
        </div>

    )
}
