import { Button, Dialog, DialogTitle, List, ListItem } from "@mui/material";
import { GitHub } from "@mui/icons-material";
import Google from '@mui/icons-material/Google';
import { signInWithGithub, signInWithGoogle } from "../../firebase";
export interface LoginDialogProps {
    open: boolean;
    onClose: () => void;
}

export const LoginDialog = (props: LoginDialogProps) => {
    const { onClose, open } = props;

    const handleGoogle = () => {
        signInWithGoogle()
        props.onClose()
    }
    const handleGithub = () => {
        signInWithGithub()
        props.onClose()
     }

    return (
        <Dialog onClose={props.onClose} open={open}>
            <DialogTitle>Login</DialogTitle>
            <List>
                <ListItem>
                    <Button color="primary" variant="outlined" onClick={handleGoogle}>
                        <Google /> &nbsp;&nbsp;Login with Google
                    </Button>
                </ListItem>
                <ListItem>
                    <Button color="primary" variant="outlined" onClick={handleGithub}>
                        <GitHub /> &nbsp;&nbsp;Login with GitHub
                    </Button>
                </ListItem>
            </List>
        </Dialog>
    );

}
