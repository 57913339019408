import { Toolbar, AppBar, LinearProgress, Button, Box, Tooltip, Divider } from "@mui/material"
import React, { useContext, useState } from "react"
import { LoginDialog } from "../login-dialog/login-dialog"
import GetAppIcon from '@mui/icons-material/GetApp';
import { UserContext } from "../../providers/user-provider";
import { UserAvatar } from "../avatar/avatar";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { LibraryBooks, DarkMode, LightMode } from "@mui/icons-material";
import { DownloadDialog } from "../download-dialog/login-dialog";
import { FilesContext } from "../../providers/files-provider";

interface AppBarProps {
    loading?: boolean
    darkMode: boolean
    toggleDarkMode: () => void
}

export const ApplicationBar = (props: AppBarProps) => {


    const user = useContext(UserContext)
    const { files } = useContext(FilesContext)
    const [login, setLogin] = useState(false);
    const [download, setDownload] = useState(false);

    const handleGetApp = () => {
        if (user === null) {
            setLogin(true)
            return;
        }
        var zip = new JSZip();
        for (let file in files) {
            console.log("Saving ", file)
            zip.file(file.substring(1), files[file]);
        }
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, "my-app.zip");
            });
    }

    const handleReadTheDocs = () => {
        const newWindow = window.open("https://docs.formify.cloud", '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <React.Fragment>
            <AppBar position="sticky" color="default">
                <Toolbar variant="dense">
                    <Box flexGrow="1" ><img src="./logo.png" alt="formify" /> </Box>
                    <Box ></Box>
                    <Tooltip title="Download App">
                        <Button variant="outlined" onClick={handleGetApp}><GetAppIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Read the Docs">
                        <Button variant="outlined" onClick={handleReadTheDocs}><LibraryBooks /></Button>
                    </Tooltip>
                    <Tooltip title="Toggle Dark Mode">
                        <Button variant="outlined" onClick={props.toggleDarkMode}>{props.darkMode ? <LightMode /> : <DarkMode/>}
                        </Button>
                    </Tooltip>

                    <Box flexGrow="1" ></Box>
                    {user ?
                        <UserAvatar />
                        : <Button style={{ marginLeft: 20 }} variant="outlined" onClick={() => setLogin(true)}>Login</Button>
                    }
                </Toolbar>
            </AppBar>
            {props.loading ? <LinearProgress /> : <LinearProgress variant="determinate" value={100} />}
            <LoginDialog open={login} onClose={() => setLogin(false)} />
            <DownloadDialog open={download} onClose={() => setDownload(false)} />
        </React.Fragment>
    )
}





