import { Avatar, Button, Menu, MenuItem, Tooltip } from "@mui/material"
import { Logout } from "@mui/icons-material"
import React, { useContext } from "react"
import { auth } from "../../firebase"
import { UserContext } from "../../providers/user-provider"

export interface AvatarProps { }

export const UserAvatar = (props: AvatarProps) => {

    const user = useContext(UserContext)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        auth.signOut()
        handleClose()
    }

    return (
        <React.Fragment>
            <Tooltip title={user.displayName}>
                <Button onClick={handleClick}>
                    <Avatar alt={user.displayName} src={user.photoURL} />
                </Button>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled={true} onClick={() => { }}>Profile</MenuItem>
                <MenuItem disabled={true} onClick={() => { }}>Settings</MenuItem>
                <MenuItem onClick={logout}><Logout /> &nbsp;&nbsp;Logout</MenuItem>
            </Menu>
        </React.Fragment>
    )
}


