 import * as ace from 'ace-builds';

ace.define('ace/mode/jrocket_highlighting_rules', function (require, exports, module) {
    var oop = ace.require("ace/lib/oop");
    var TextHighlightRules = ace.require("ace/mode/text_highlight_rules").TextHighlightRules;

    var DiagramHighlightRules = function () {
        var keywords = (
            "app|model|prop|operation|op|mode|primary|secondary|accent|dark|light|colors|entity" +
            "entity|extends|enum|view|" +
            "master-detail|master|detail|collection|composite|tab-composite|wizard|grid|parts|form|" +
            "value|field|visible|editable|services|service|label"
        );

        var builtinConstants = (
            "true|false|yes|no"
        );

        var builtinFunctions = (
            "min|max|length|lessThan|moreThan|positive|negative|email|url|required|lowercase|uppercase"
        );

        var types = ("boolean|string|number|array|date|time|image|color")
        //ace.operator
        var keywordMapper = this.createKeywordMapper({
            "constant.language": keywords + "|" + builtinFunctions,
            "support.function" : builtinConstants + "|" + builtinFunctions,
            //"support.type" : types,
            "keyword.operator" : types,
            //"variable.language" : "types"
        }, "identifier", true);

        this.$rules = {
            "start": [
                {
                    token: "comment",
                    regex: "\\/\\/.*$"
                },
                {
                    token: "comment", // multi line comment
                    regex: "\\/\\*",
                    next: "comment"
                }, {
                    token: "string", // single line
                    regex: '["](?:(?:\\\\.)|(?:[^"\\\\]))*?["]'
                }, {
                    token: "string", // single line
                    regex: "['](?:(?:\\\\.)|(?:[^'\\\\]))*?[']"
                }, {
                    token: "constant.numeric", // hex
                    regex: /0(?:[xX][0-9a-fA-F][0-9a-fA-F_]*|[bB][01][01_]*)[LlSsDdFfYy]?\b/
                }, {
                    token: "constant.numeric", // float
                    regex: /[+-]?\d[\d_]*(?:(?:\.[\d_]*)?(?:[eE][+-]?[\d_]+)?)?[LlSsDdFfYy]?\b/
                }, {
                    token: "constant.language.boolean",
                    regex: "(?:true|false)\\b"
                }, {
                    regex: "(open(?:\\s+))?module(?=\\s*\\w)",
                    token: "keyword",
                    next: [{
                        regex: "{",
                        token: "paren.lparen",
                        next: [{
                            regex: "}",
                            token: "paren.rparen",
                            next: "start"
                        }]
                    }, {
                        token: "text",
                        regex: "\\s+"
                    }, {
                        token: "identifier",
                        regex: "\\w+"
                    }, {
                        token: "punctuation.operator",
                        regex: "."
                    }, {
                        token: "text",
                        regex: "\\s+"
                    }, {
                        regex: "",
                        next: "start"
                    }]
                }, {
                    token: keywordMapper,
                    regex: "[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
                }, {
                    token: "keyword.operator",
                    regex: "!|\\$|%|&|\\*|\\-\\-|\\-|\\+\\+|\\+|~|===|==|=|!=|!==|<=|>=|<<=|>>=|>>>=|<>|<|>|!|&&|\\|\\||\\?\\:|\\*=|%=|\\+=|\\-=|&=|\\^="
                }, {
                    token: "lparen",
                    regex: "[[({]"
                }, {
                    token: "rparen",
                    regex: "[\\])}]"
                }, {
                    token: "text",
                    regex: "\\s+"
                }
            ],
            "comment": [
                {
                    token: "comment", // closing comment
                    regex: "\\*\\/",
                    next: "start"
                }, {
                    defaultToken: "comment"
                }
            ]
        };
        this.normalizeRules();
    }

    oop.inherits(DiagramHighlightRules, TextHighlightRules);

    exports.DiagramHighlightRules = DiagramHighlightRules;
});

ace.define('ace/mode/jrocket', function (require, exports, module) {
    "use strict";
    var oop = ace.require("ace/lib/oop");
    var TextMode = require("ace/mode/text").Mode;
    var Tokenizer = require("../tokenizer").Tokenizer;
    var DiagramHighlightRules = require("ace/mode/jrocket_highlighting_rules").DiagramHighlightRules;
    //var FoldMode = require("./folding/cstyle").FoldMode;

    var Mode = function () {
        var highlighter = new DiagramHighlightRules();
        //this.foldingRules = new FoldMode();
        this.$tokenizer = new Tokenizer(highlighter.getRules());
        this.$keywordList = highlighter.$keywordList;
    };
    oop.inherits(Mode, TextMode);

    (function () {
        this.lineCommentStart = "'";
    }).call(Mode.prototype);

    exports.Mode = Mode;
});
